import { motion } from 'framer-motion'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { CmsPage } from '../../cms'
import { SectionBlockElement } from '../../cms/section-blocks'
import { Columns, Content, Heading, Rows } from '../../design-system'
import { Box } from '../../design-system/atoms/box'
import { NoSSR } from '../../design-system/atoms/no-ssr'
import { styled } from '../../design-system/styled'
import { Button } from '../../twt-core/atoms/button'
import { LayoutCard } from '../../twt-core/molecules/layout-card'
import { getUserTimezone } from '../../util'
import { Event } from '../molecules/__generated__/Event'
import {
  BookmarkButton, EventHeader, EventParticipants, EventTimings
} from '../organisms'
import { RegistrationScreen } from '../organisms/registration'
import { useEventBookmarked } from '../services/event-bookmark'


export const query = graphql`
  fragment EventPageData on WAGTAIL_Event {
    ...Event
    ...CmsPageData

    ... on WAGTAIL_Event {
      externalRegistrationUrl
      customRegistrationButtonText
      searchDescription
      image {
        src
      }
      detail {
        id
        ...SectionBlock
      }
      venue {
        name
      }
      theme
      parent {
        url
        title
        ... on WAGTAIL_HomePage {
          colour
          events {
            ...Event
          }
        }
      }
    }
  }

  query EventPage($id: Int!) {
    wagtail {
      ...CmsSiteData

      page(id: $id) {
        ...EventPageData
      }
    }
  }
`

const BookmarkSuccessModal: React.FC<{
  event: Event
  onCancel: any
  onClose: any
  capacityStatus?: string
}> = ({ event, onCancel, onClose, capacityStatus }) => {
  const [confirmed, setConfirmed] = useState<boolean>(
    capacityStatus.includes('Unlimited'),
  )
  return (
    <Modal center open={true} onClose={() => onClose()}>
      {!confirmed && (
        <LayoutCard>
          <LayoutCard>
            <Heading style={{ marginBottom: 3 }}>
              By registering, you will be taking up a space in a
              capacity-limited session.
            </Heading>

            <Content style={{ margin: '4px 0' }}>
              Please only do this if you are definitely going to attend on the
              day!
            </Content>

            <Content style={{ margin: '4px 0 18px' }}>
              Still want to continue?
            </Content>

            <Button
              onClick={() => {
                setConfirmed(true)
              }}
            >
              Continue
            </Button>
            <Button
              onClick={() => {
                onCancel()
                onClose()
              }}
            >
              Cancel
            </Button>
          </LayoutCard>
        </LayoutCard>
      )}
      {confirmed && (
        <LayoutCard>
          <LayoutCard>
            <Heading variant="small" style={{ marginBottom: 3 }}>
              ✅ Success
            </Heading>
            <Heading>You’re going to</Heading>
            <Heading color="navy">{event.title}</Heading>
            {event.startTime && event.endTime && (
              <Box color="navy" width="auto" display="inline-block">
                <EventTimings {...event} />
              </Box>
            )}

            <Content style={{ margin: '18px 0' }}>
              Thanks for booking onto this event! We have sent you a
              confirmation email - please be sure to check your spam folders.
            </Content>

            <Content style={{ margin: '18px 0' }}>
              Importantly,{' '}
              <b>
                if your plans change and you can no longer attend this event
                please ensure you cancel your booking.
              </b>{' '}
              There are limited places on many events throughout the program and
              we want to ensure as many people who want to attend each event are
              able to.
            </Content>

            <Content style={{ margin: '18px 0' }}>
              We will send you further information on how to attend the session
              on the day of the event - including a link and any troubleshooting
              tips in case your technology is playing up.
            </Content>
          </LayoutCard>
          <Content>&nbsp;</Content>
          <Content>
            <Button onClick={onClose}>&larr;Back to page</Button>
          </Content>
        </LayoutCard>
      )}
    </Modal>
  )
}

const EventPage = ({ data }) => {
  const event = data?.wagtail?.page
  const bookmark = useEventBookmarked(
    event?.id,
    RegistrationScreen,
    event.externalRegistrationUrl,
  )
  const [modalContent, setModalContent] = useState<any>(null)

  const resetModal = () => {
    setModalContent(null)
  }

  useEffect(() => {
    const lastActionInSession = bookmark?.actions[bookmark?.actions.length - 1]

    if (lastActionInSession === 'bookmark') {
      setModalContent(
        <BookmarkSuccessModal
          capacityStatus={bookmark.capacityStatus}
          onClose={resetModal}
          event={bookmark.event}
          onCancel={bookmark.unbookmark}
        />,
      )
    }
  }, [bookmark?.actions])

  const userTimezone = getUserTimezone()
  const isNotInEventTimezone = userTimezone && userTimezone !== event?.timezone

  return (
    <>
      <Petal
        src="/petal-6.png"
        initial={{ opacity: 0, y: -50, rotate: 10, scale: 0.6 }}
        animate={{ opacity: 1, y: 0, rotate: 0 }}
        transition={{ duration: 3.5, delay: 1.5 }}
      />
      <CmsPage noTitle {...data.wagtail}>
        {modalContent}
        <Rows spacing={3} css={{ paddingBottom: '42px' }}>
          <LayoutCard>
            <Columns
              flip={{ mobile: true, desktop: false }}
              alignItems="flex-start"
              spacing={4}
              flipSpacing={2}
            >
              <Rows
                width={{ mobile: '100%', desktop: '50%' }}
                marginBottom={{ mobile: 4, desktop: 4 }}
                spacing={3}
              >
                <Rows spacing={2}>
                  <h1
                    css={{
                      fontFamily: 'AA Gothic',
                      fontWeight: 'bold',
                      fontSize: '62px',
                      lineHeight: '68px',
                      marginTop: '0',
                    }}
                  >
                    {event && event.title}
                  </h1>

                  {event.startTime && event.endTime && (
                    <>
                      <EventTimings {...event} />
                      <NoSSR>
                        {isNotInEventTimezone && (
                          <EventTimings {...event} timezone={userTimezone} />
                        )}
                      </NoSSR>
                    </>
                  )}

                  {event?.venue?.length ? (
                    <Box
                      css={{
                        fontFamily: 'AA Gothic',
                        fontWeight: 'bold',
                        marginTop: '8px',
                        fontSize: '32px',
                        lineHeight: '36px',
                      }}
                    >
                      {event.venue.map((venue) => venue.name).join(' | ')}
                    </Box>
                  ) : null}
                </Rows>
                {event.description && (
                  <Content
                    css={{
                      fontFamily: 'Franklin Gothic Book',
                      fontSize: '24px',
                      lineHeight: '29px',
                    }}
                    dangerouslySetInnerHTML={{ __html: event.description }}
                  />
                )}

              </Rows>

              <Rows
                width={{ mobile: '100%', desktop: '50%' }}
                spacing={{ mobile: 1, desktop: 4 }}
                marginBottom={{ mobile: 4, desktop: 0 }}
              >
                <EventHeader event={event} />

                <Columns>
                  <BookmarkButton
                    bookmark={bookmark}
                    eventId={event.id}
                    registerText={event.customRegistrationButtonText}
                    registerURL={event.externalRegistrationUrl}
                  />
                </Columns>
              </Rows>
            </Columns>
            <Columns
              flip={{ mobile: true, desktop: false }}
              alignItems="flex-start"
              spacing={4}
              flipSpacing={2}
            >
              <Rows
                width={{ mobile: '100%', desktop: '50%' }}
                marginBottom={{ mobile: 0, desktop: -4 }}
                spacing={4}
              >
                <EventParticipants
                  title="Particpants/Speakers"
                  items={
                    event.speakers &&
                    event.speakers.map((s) => ({
                      ...s,
                      description: s.bio,
                    }))
                  }
                />
              </Rows>

              <Rows
                width={{ mobile: '100%', desktop: '50%' }}
                spacing={{ mobile: 1, desktop: 4 }}
              >
                <EventParticipants
                  title="Sponsoring Organisations"
                  items={event.organisations}
                />
              </Rows>
            </Columns>
          </LayoutCard>

          {event?.detail.filter(Boolean).map((item, i) => (
            <SectionBlockElement
              key={item.id}
              block={item}
              index={i + 2}
              context="embed"
            />
          ))}
        </Rows>
      </CmsPage>
    </>
  )
}

const Petal = styled(motion.img)`
  position: fixed;
  pointer-events: none;
  z-index: -1;
  top: -20vw;
  right: -40vw;
  width: 100%;
  height: auto;
  overflow: hidden;

  &:last-of-type {
    left: inherit;
  }
`
export default EventPage
