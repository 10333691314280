import css from '@emotion/css'
import React, { ReactNode, ComponentType, MouseEvent, useCallback } from 'react'
import {
  styled,
  Columns,
  FlexProps,
  Themed,
  Text,
  Link,
  Box,
  BoxProps,
  BoxLink,
  spacingLevel,
} from '../../design-system'

const Button = styled(Columns.withComponent('button'))`
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.5;
  }
`

export const Field = styled(Box)`
  ${({ theme }) => theme.font('controlContent')}
  border-bottom: 2px solid ${({ theme }) => theme.color('contentAlt')};
`

type LinkButtonProps = FlexProps & { to: string }

export const LinkButton = Button.withComponent(Link) as ComponentType<
  LinkButtonProps
>

const chipMixin = ({ theme, color }: Themed<FlexProps>) => css`
  border: 2px solid ${theme.color(color)};
  border-radius: 9px;
  text-align: center;
`

export const ChipButton = styled(Button)<FlexProps>`
  ${chipMixin}
`

export const ChipLink = styled(BoxLink)<FlexProps>`
  ${chipMixin}
`

export const Chip = styled(Columns)<FlexProps>`
  ${chipMixin}
`

export const InlineLink = ({
  prefixView: prefix,
  children,
  ...props
}: LinkButtonProps & {
  prefixView?: ReactNode
}) => (
  <LinkButton alignItems="center" spacing={1} {...props}>
    {prefix}
    <Text color="contentAlt" variant="controlLabel">
      {children}
    </Text>
  </LinkButton>
)

export const InlineButton = ({
  prefixView: prefix,
  children,
  ...props
}: BoxProps & {
  prefixView?: ReactNode
}) => (
  <Button alignItems="center" spacing={1} {...props}>
    {prefix}
    <Text color="contentAlt" variant="controlLabel">
      {children}
    </Text>
  </Button>
)

const SolidButtonWrapper = styled(Button)`
  border-radius: 11px;
  padding: ${spacingLevel(2)}px ${spacingLevel(3)}px;
`

export const SolidButton = ({
  prefixView: prefix,
  children,
  color,
  ...props
}: BoxProps & {
  prefixView?: ReactNode
}) => (
  <SolidButtonWrapper alignItems="center" spacing={1} {...props}>
    {prefix}
    <Text color={color || 'bg'} variant="controlContent">
      {children}
    </Text>
  </SolidButtonWrapper>
)
