/** @jsx jsx */

import { jsx } from '@emotion/core'
import { parseToRgb, rgbToColorString } from 'polished'
import { FC, Fragment } from 'react'
import {
  Box, BoxProps, Columns,
  Content, ContentAccordion, ResponsiveImage, Rows, Text, useTheme
} from '../../design-system'
import { styled } from '../../design-system/styled'
import { ChipLink } from '../../twt-2019/atoms/controls'
import {
  Button, Chip, ChipGrid, Control, TwitterProfileLink,
  WebsiteProfileLink
} from '../../twt-core'
import { formatDate, formatTime } from '../../util'
import {
  EventBookmarkedInfo
} from '../services/event-bookmark'

export const EventHeader = ({ event }) => {
  const color = parseToRgb(event.parent.colour || 'white')

  return (
    <ResponsiveImage width="100%" height="414px" image={event.image}>
      <Box
        bg={rgbToColorString({ ...color, alpha: 0.3 })}
        width="100%"
        height="100%"
      />
    </ResponsiveImage>
  )
}

const StyledChipLink = styled(Chip.withComponent(ChipLink))`
  border-radius: 0px;
`

export const EventFormats: FC<{ event: EventDetailFragment } & BoxProps> = ({
  event,
  ...props
}) => {
  if (!event.format || event.format.length === 0) {
    return null
  }

  return (
    <ChipGrid>
      {event.format.map((tag) => (
        <StyledChipLink
          to={`/twt20/calendar?format=${encodeURIComponent(tag.name)}`}
          color={event.parent.colour || 'indigo'}
          key={tag.id}
        >
          {tag.name}
        </StyledChipLink>
      ))}
    </ChipGrid>
  )
}

export const EventTopics: FC<{ event: EventDetailFragment } & BoxProps> = ({
  event,
  ...props
}) => {
  if (!event.topics || event.topics.length === 0) {
    return null
  }

  return (
    <Control label="Tags" {...props}>
      <ChipGrid>
        {event.topics.map((tag) => (
          <StyledChipLink
            to={`/twt20/calendar?topic=${encodeURIComponent(tag.name)}`}
            color={event.parent.colour || 'indigo'}
            key={tag.id}
          >
            {tag.name}
          </StyledChipLink>
        ))}
      </ChipGrid>
    </Control>
  )
}

interface EventParticipant {
  id: string
  name: string
  description: string
  twitter?: string
  website?: string
}

export const EventParticipants: FC<
  {
    title: string
    items: EventParticipant[]
    color?: string
    textColor?: string
  } & BoxProps
> = ({
  title,
  items = [],
  color = 'content',
  textColor = 'contentLight',
  ...props
}) => {
  const theme = useTheme()

  if (items.length === 0) {
    return null
  }

  return (
    // ig
    <Control
      label={title}
      labelStyle={{
        textTransform: 'none',
        fontSize: '24px',
        fontWeight: 'bold',
        marginLeft: '24px',
        color: '#1E008E',
        opacity: '1',
      }}
      {...props}
    >
      <Fragment>
        {items.map((x) => {
          const hasProfile = Boolean(x.twitter || x.website)

          return (
            <ContentAccordion
              label={x.name}
              key={x.id}
              color={theme.color(color)}
              rowBorder={`2px solid ${theme.color(color)}`}
            >
              {(hasProfile || x.description) && (
                <Rows spacing={2}>
                  {x.description && (
                    <Content
                      color={theme.color(textColor)}
                      dangerouslySetInnerHTML={{ __html: x.description }}
                    />
                  )}
                  {hasProfile && (
                    <Columns spacing={2}>
                      <TwitterProfileLink
                        color={theme.color(textColor)}
                        value={x.twitter}
                      />
                      <WebsiteProfileLink
                        color={theme.color(textColor)}
                        value={x.website}
                      />
                    </Columns>
                  )}
                </Rows>
              )}
            </ContentAccordion>
          )
        })}
      </Fragment>
    </Control>
  )
}

export const BookmarkButton: FC<{
  bookmark: EventBookmarkedInfo
  eventId: string
  registerText: string
  registerURL?: string
}> = ({
  bookmark,
  registerText = 'Register to attend',
  registerURL,
}) => {
  if (!bookmark) {
    return null
  }
  
  if (registerURL) {
    return (
      <Fragment>
        <a
          href={registerURL}
          css={{
            width: '100%',
            backgroundColor: '#00FF80',
            fontSize: '32px',
            fontWeight: 'bold',
            border: 'none',
            padding: '10px 24px',
            position: 'relative',
            color: 'inherit',
            textDecoration: 'none'
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          {registerText || 'Click here to register'}
          <span css={{ position: 'absolute', right: '20px' }}>&gt;</span>
        </a>
      </Fragment>
    )
  }

  if (bookmark.isBookmarked) {
    return (
      <Fragment>
        {bookmark.loginView}

        <Columns spacing={2}>
          <Button onClick={bookmark.unbookmark}>Cancel attendance</Button>
        </Columns>
      </Fragment>
    )
  }

  if (bookmark.canRegister) {
    return (
      <Fragment>
        {bookmark.loginView}

        <Button
          onClick={bookmark.bookmark}
          css={{
            width: '100%',
            backgroundColor: '#00FF80',
            fontSize: '32px',
            fontWeight: 'bold',
            border: 'none',
            padding: '10px 24px',
            position: 'relative',
          }}
        >
          {registerText || 'Click here to register'}
          <span css={{ position: 'absolute', right: '20px' }}>&gt;</span>
        </Button>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {bookmark.capacityStatus === 'Full' ? (
        <Text>
          <b>This event is fully booked!</b> Please check back at a later date
          as some spaces may become available if other attendees are no longer
          able to make it.
        </Text>
      ) : (
        <Text>
          To attend this event, make sure to buy your TWT21 ticket{' '}
          <a
            href="https://www.tickettailor.com/events/theworldtransformed/554783"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Text>
      )}
    </Fragment>
  )
}

export const EventTimings: FC<{
  startTime: string | Date
  endTime: string | Date
  timezone?: string | undefined
}> = ({ startTime, endTime, timezone }) => {
  return (
    <Box
      css={{
        fontFamily: 'AA Gothic',
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '36px',
        borderTop: '3px solid #FFE4A9',
        borderBottom: '3px solid #FFE4A9',
        padding: '18px 0',
      }}
    >
      <span css={{ display: 'block' }}>{formatDate(startTime, timezone)}</span>
      <span css={{ display: 'block' }}>
        {formatTime(startTime, timezone)}–{formatTime(endTime, timezone)}
      </span>
      {/* <Text color="grey">
        {' '}
        {formatTimezone(startTime, timezone, 'zzz / zzzz')}
      </Text> */}
    </Box>
  )
}

export const EventCapacity: FC<{
  status?: string
}> = ({ status }) => {
  return (
    <Box css={{ fontWeight: 500 }}>
      <Text css={{ textTransform: 'uppercase' }}>Capacity:</Text>
      <Text color="indigo">&nbsp;{status ? status : 'Loading...'}</Text>
    </Box>
  )
}
