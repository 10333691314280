export const getPageComponentFromContentType = (contentType: string) => {
  switch (contentType.toLowerCase()) {
    case 'home.event':
      return require.resolve('./festival/pages/event-page.tsx')
    default:
      return require.resolve('./twt-core/pages/default-page.tsx')
  }
}

export const getPageRequireFromContentType = (contentType: string) => {
  switch (contentType) {
    case 'home.event':
      return require('./festival/pages/event-page.tsx').default
    default:
      return require('./twt-core/pages/default-page.tsx').default
  }
}
