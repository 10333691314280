import { HttpLink } from 'apollo-link-http'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { ApolloClient } from '@apollo/client'
import { getIsolatedQuery } from 'gatsby-source-graphql-universal'
import { useQuery } from '@apollo/react-hooks'
import React, { useMemo, FC } from 'react'
import { PageProps, graphql } from 'gatsby'
import { parse } from 'querystring'
import { useAsync } from 'react-use'
import { getPageRequireFromContentType } from './page-type'
import './festival/pages/event-page'

export const query = graphql`
  query PreviewQuery($token: String, $contentType: String) {
    wagtail {
      home: page(slug: "home") {
        ...CmsHomePageData
      }
      page(token: $token, contentType: $contentType) {
        ...DefaultPageData
        ...EventPageData
      }
      logoGraveyard: partners(preview: true) {
        ...LogoGraveyard
      }
      popups: page(slug: "popups", token: $token) {
        ...PopupConfig
      }
    }
  }
`

const introspectSchema = () =>
  fetch(`${process.env.GATSBY_CMS_URL}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      variables: {},
      query: `
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `,
    }),
  }).then((res) =>
    res.ok
      ? res.json().then((json) => json.data)
      : Promise.reject('Schema introspection failure'),
  )

const PreviewRoot: FC<PageProps> = (props) => {
  const client = useAsync(
    async () =>
      new ApolloClient({
        link: new HttpLink({
          uri: process.env.GATSBY_CMS_URL + '/graphql',
        }),
        cache: new InMemoryCache({
          fragmentMatcher: new IntrospectionFragmentMatcher({
            introspectionQueryResultData: await introspectSchema(),
          }),
        }),
      }),
    [],
  )

  if (!client.value) {
    return null
  }

  const search = parse(props.location.search.replace(/^\?/, ''))
  if (
    typeof search.content_type !== 'string' ||
    typeof search.token !== 'string'
  ) {
    return null
  }

  return (
    <PreviewPage
      contentType={search.content_type}
      token={search.token}
      client={client.value}
      {...props}
    />
  )
}

const PreviewPage: FC<
  PageProps<{}, { queries: string[] }> & {
    token: string
    contentType: string
    client: ApolloClient<{}>
  }
> = ({ token, contentType, client, pageContext, ...pageProps }) => {
  const query = useMemo(() => {
    const pageQuery = pageContext.queries.find((q) =>
      q.includes('query PreviewQuery'),
    )
    return getIsolatedQuery(pageQuery, 'wagtail', 'WAGTAIL')
  }, [])

  // Call through to the query for that module
  const res = useQuery(query, {
    client,
    variables: {
      siteSlug: 'home',
      contentType,
      token,
    },
  })

  if (res.loading) {
    return null
  }

  if (res.error) {
    throw res.error
  }

  // Call through to the root component with the data
  const PageComponent = getPageRequireFromContentType(contentType)

  return <PageComponent {...pageProps} data={{ wagtail: res.data }} />
}

export default PreviewRoot
