import { eventGroupCardApolloFragments } from './../molecules/event-group-card';
import { eventCardApolloFragments } from './../molecules/event-card';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import { ReactNode } from 'react'
import { useAuthGuard, AuthFlow } from '../../twt-core/organisms/login-screen'
import useArrayState from 'use-array-state';

const EVENT_BOOKMARKED_QUERY = gql`
  query GetBookmarkedEvents($id: Int!) {
    event(id: $id) {
      id
      title
      capacityStatus
      canRegister
      startTime
      endTime
    }
    currentUser {
      id
      bookmarkedEvents {
        id
        isBookmarked
      }
    }
  }

  ${eventCardApolloFragments}
  ${eventGroupCardApolloFragments}
`

const EVENT_BOOKMARK_MUT = gql`
  mutation BookmarkAnEvent($id: ID) {
    bookmarkEvent(id: $id) {
      success
      currentUser {
        id
        bookmarkedEvents {
          id
          isBookmarked
        }
      }
    }
  }
`

const EVENT_UNBOOKMARK_MUT = gql`
  mutation UnbookmarkAnEvent($id: ID) {
    unbookmarkEvent(id: $id) {
      success
      currentUser {
        id
        bookmarkedEvents {
          id
          isBookmarked
        }
      }
    }
  }
`

type Action = 'bookmark' | 'unbookmark'

export interface EventBookmarkedInfo {
  canRegister: boolean
  capacityStatus: string
  isBookmarked: boolean
  bookmark: () => void
  unbookmark: () => void
  loginView: ReactNode
  actions: Action[]
}

export const useEventBookmarked = (
  eventId: string,
  authFlow?: AuthFlow,
  registrationUrl?: string
): EventBookmarkedInfo | undefined => {
  const [loginView, guard] = useAuthGuard(authFlow)
  const [actions, actionOps] = useArrayState<Action>([])

  const res = useQuery(EVENT_BOOKMARKED_QUERY, {
    variables: {
      id: eventId
    }
  })

  const [bookmark, bookmarkState] = useMutation(EVENT_BOOKMARK_MUT, {
    variables: {
      id: eventId,
    },
  })

  const [unbookmark, unbookmarkState] = useMutation(EVENT_UNBOOKMARK_MUT, {
    variables: {
      id: eventId,
    },
  })

  const mutations = {
    bookmark: guard(registrationUrl ? () => window.location.href = registrationUrl : (...args) => {
      actionOps.push('bookmark');
      return bookmark(...args)
    }),
    unbookmark: guard((...args) => {
      actionOps.push('unbookmark');
      return unbookmark(...args)
    }),
    pending: false,
    loginView,
  }

  if (res.error) {
    throw new ApolloError(res.error)
  }

  if (!res.data) {
    return undefined
  }

  if (res.data.currentUser) {
    const match = res.data.currentUser.bookmarkedEvents.find(
      e => e.id === eventId,
    )

    if (match) {
      return { event: res.data.event, ...res.data.event, ...match, ...mutations, actions }
    }
  }

  return { event: res.data.event, ...res.data.event, ...mutations, isBookmarked: false, actions }
}
