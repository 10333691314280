// State machine

import qs from 'query-string'
import React, { useEffect, useState, FC, Fragment } from 'react'
import { themeColors } from '../../twt-2021/theme'
import { LoginScreen, Control, Field, Button } from '../../twt-core'
import {
  Heading,
  Box,
  Content,
  Rows,
  BoxProps,
  styled,
  spacingLevel,
  Grid,
  Flex,
  VideoEmbed,
  Form,
  FormField,
  FormSubmit,
  CaptionSmall,
  LinkExternal,
} from '../../design-system'
import { DonationForm } from '../../twt-core/organisms/donation-form'
import gql from 'graphql-tag'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { useGraphQlForm } from '../../services'
import { Text } from '../../design-system/atoms/text'
import { ButtonLink } from '../../twt-core/atoms/button'
import { css } from '@emotion/core'
import { SectionWrapper } from '../../twt-core/molecules/section'

const ContentWrapper: FC = ({ ticketType, ...props }) => {
  return <Box maxWidth="860px" style={{ margin: 0 }} {...props} />
}

// TODO: Does this pattern generalise out to other blocks in a way that fits in with the CMS content schema?
const RoundButton = styled(Box.withComponent('button'))<
  BoxProps & { emphasied?: boolean }
>`
  display: inline-block;
  cursor: pointer;
  font-family: FranklinGothic;
  text-decoration: none;
  border-radius: 500px;
  padding: 9px 23px;
  background-color: #00ff80;
  color: #1e0096;
  font-size: 16px;
  margin-right: 13px;
  border: none;
  margin-top: auto;
`

const TICKET_TYPES = {
  free: {
    title: 'Standard festival pass',
    color: 'darkPink',
    icon: <img src="/petal-3.png" />,
    description:
      'Discounted access to the festival program, for all those who need it',
  },
  solidarity: {
    title: 'Solidarity festival pass',
    color: 'yellow',
    icon: <img src="/petal-5.png" />,
    description:
      'A one-off contribution to help with the running costs of the TWT21 festival',
  },
  supporter: {
    title: 'Supporter festival pass',
    color: 'pink',
    icon: <img src="/petal-1.png" />,
    description:
      'An ongoing contribution to help us deliver transformative political education all year round',
  },
}

const Ticket = ({ type, cta = undefined, ...props }) => (
  <Rows
    alignItems="center"
    padding={2}
    spacing={2}
    bg={TICKET_TYPES[type].color}
    {...props}
  >
    {TICKET_TYPES[type].icon}
    <Heading level={3} variant="heading">
      {TICKET_TYPES[type].title}
    </Heading>
    <Content variant="small">{TICKET_TYPES[type].description}</Content>
    {cta}
  </Rows>
)

const IntroBlock = ({ title, children, variant = 'heading' }) => (
  <>
    <SectionWrapper paddingVertical={{ mobile: 1, desktop: 1 }}>
      <ContentWrapper>
        <Heading variant={variant}>{title}</Heading>
      </ContentWrapper>
    </SectionWrapper>

    <SectionWrapper paddingVertical={{ mobile: 1, desktop: 1 }}>
      <ContentWrapper>
        <Content>{children}</Content>
      </ContentWrapper>
    </SectionWrapper>
  </>
)

const IntroductionPanel: React.FC<{
  allowLogin?: boolean
  optionHandler?: any
  title?: string
  size?: 'large' | 'small'
}> = ({ allowLogin, optionHandler, title, size }) => (
  <>
    {allowLogin && (
      <Rows
        spacing={1}
        css={css`
          padding: ${spacingLevel(3)}px;
          padding-top: 0;
          max-width: 40em;

          @media screen and (min-width: 768px) {
            text-align: right;

            float: right;
          }
        `}
      >
        <Heading variant="small" color="navy">
          Already registered?
        </Heading>
        <Button
          onClick={optionHandler('will-login')}
          alignSelf="flex-start"
          bg="contentAlt"
          css={{
            zIndex: 5,
            width: '100%',
            backgroundColor: '#00FF80',
            fontSize: '32px',
            fontWeight: 'bold',
            border: 'none',
            padding: '10px 24px',
            position: 'relative',
            marginTop: '1rem',
          }}
        >
          <span>Sign in</span>
          <span css={{ position: 'absolute', right: '20px' }}>&gt;</span>
        </Button>
      </Rows>
    )}

    <IntroBlock
      title={title || 'Register for your Festival Pass'}
      variant={size === 'large' ? 'display' : 'heading'}
    >
      <p>
        Thank you for your interest in TWT21. To book onto any online sessions for this year's festival, you must register for an online festival pass. If you're attending TWT21 physically in Brighton, please make sure you've bought your festival ticket <a href="https://www.tickettailor.com/events/theworldtransformed/554783">here</a>.
      </p>
      <p>
        TWT21 is made possible by regular donations from our supporters. But if you have the means, please consider making a one-off contribution or becoming a supporter to support the production of online content for TWT21. 
      </p>
    </IntroBlock>

    <SectionWrapper>
      <Grid gridFrom="desktop" alignItems="stretch" columnSize={150}>
        <Ticket
          style={{ filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16))' }}
          type="free"
          cta={
            <RoundButton onClick={optionHandler('free')}>Free &gt;</RoundButton>
          }
        />
        <Ticket
          style={{ filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16))' }}
          type="solidarity"
          cta={
            <RoundButton onClick={optionHandler('solidarity')}>
              £10 &gt;
            </RoundButton>
          }
        />
        <Ticket
          type="supporter"
          style={{ filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16))' }}
          cta={
            <RoundButton onClick={optionHandler('supporter')}>
              Support us &gt;
            </RoundButton>
          }
        />
      </Grid>
    </SectionWrapper>

    <Box width="100%" bg="black">
      <VideoEmbed
        height="calc(100vw * 0.5625)"
        maxHeight="100vh"
        video="LDkCT_miFdI"
      />
    </Box>
  </>
)

const ContentWrapperForRegisterPanel = (props) => (
  <SectionWrapper>
    <ContentWrapper>
      <Rows spacing={2} {...props} />
    </ContentWrapper>
  </SectionWrapper>
)

const RegisterPanel: React.FC<{
  onSuccess: any
  type: 'registration' | 'login'
}> = ({ onSuccess, type = 'registration' }) => {
  const isRegistration = type === 'registration'

  return (
    <LoginScreen
      ContentWrapper={ContentWrapperForRegisterPanel}
      messages={{
        login: (
          <IntroBlock title={isRegistration ? 'Sign up' : 'Sign in'}>
            {isRegistration
              ? 'This year you can sign up with Facebook or email and it will take a few minutes.'
              : null}
          </IntroBlock>
        ),
        email: (
          <IntroBlock
            title={isRegistration ? 'Sign up with Email' : 'Sign in with Email'}
          >
            Enter your email.{' '}
            {isRegistration ? (
              <span>
                We’ll send your <b>invite code</b> to your email address.
              </span>
            ) : (
              <span>
                We'll send your <b>sign in code</b> to your email address.
              </span>
            )}
          </IntroBlock>
        ),
      }}
      render={{
        waitLink: (form) => (
          <Fragment>
            <IntroBlock title="Check your email">
              <Rows spacing={2}>
                <Content>
                  {isRegistration ? (
                    <div>
                      <span>
                        We sent a confirmation code to your email address.
                      </span>
                      <br />
                      <span>
                        Enter the 6 digit code here to confirm your email
                        address:
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span>We sent a sign in code to your email address.</span>
                      <br />
                      <span>Enter the 6 digit code here to sign in.</span>
                    </div>
                  )}
                </Content>
                {form}
                <Box paddingTop={2}>
                  <Content variant="small" color="darkGrey">
                    <b>Can't find the code in your inbox?</b>
                    <ul>
                      <li>
                        If you’re using <b>Gmail</b>, make sure you check your
                        ‘Promotions’ tab. We’ve had reports of some emails going
                        there.
                      </li>
                      <li>
                        Search your inbox (and spam folder) for an email from{' '}
                        <i>info@theworldtransformed.org</i>.
                      </li>
                      <li>
                        If you can’t find one, try refreshing the page and
                        starting again, or signing in with Facebook.
                      </li>
                      <li>
                        If you're still stuck, tell{' '}
                        <a href="mailto:info@theworldtransformed.org">
                          info@theworldtransformed.org
                        </a>{' '}
                        which events you want to register for and one of our
                        volunteers will help.
                      </li>
                    </ul>
                  </Content>
                </Box>
              </Rows>
            </IntroBlock>
          </Fragment>
        ),
      }}
      onCompleted={onSuccess}
    />
  )
}

const USER_PROFILE = gql`
  query currentUser {
    currentUser {
      id
      email
      firstName
      lastName
    }
  }
`

const PaymentPanel: React.FC<{ redirectPath?: string }> = ({
  redirectPath,
}) => {
  const { loading, data } = useQuery(USER_PROFILE, {
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <>Loading</>
  }

  let hidePersonalDetails = true
  let hideEmailAddress = false

  if (data.currentUser.firstName === '') {
    hidePersonalDetails = false
  }

  if (data.currentUser.lastName === '') {
    hidePersonalDetails = false
  }

  if (data.currentUser.email) {
    hideEmailAddress = true
  }

  return (
    <>
      <IntroBlock title="You’re almost there">
        <p>
          How much would you like to pay for your solidarity festival pass? The
          most common amount people contribute is £10, but you are also welcome
          to enter another amount if you wish.
        </p>
        <p>
          The World Transformed is a small independent organisation running on a
          very limited budget. Your one-off contribution will help with the
          running costs of this year’s festival.
        </p>
        <p>
          Once you have entered the amount, you will be taken to Stripe payments
          to complete your purchase.
        </p>
      </IntroBlock>

      <SectionWrapper>
        <DonationForm
          mailchimpTags={['TWT2020 Solidarity']}
          amounts={[
            {
              value: 10,
              stripeId:
                process.env.NODE_ENV === 'development'
                  ? 'price_1HClzfK9ETq2J8d02JO8RBpm'
                  : 'price_1HCjvpK9ETq2J8d0gu4qtc9P',
            },
            {
              value: 20,
              stripeId:
                process.env.NODE_ENV === 'development'
                  ? 'price_1HI2s8K9ETq2J8d049ijUOgN'
                  : 'price_1HCjw1K9ETq2J8d0yr1rB7YV',
            },
            {
              value: 40,
              stripeId:
                process.env.NODE_ENV === 'development'
                  ? 'price_1HI2sLK9ETq2J8d0lfZPGJua'
                  : 'price_1HCjwbK9ETq2J8d0kULvOyxA',
            },
            {
              value: 60,
              stripeId:
                process.env.NODE_ENV === 'development'
                  ? 'price_1HI2sGK9ETq2J8d0nnSyf0f3'
                  : 'price_1HCjx1K9ETq2J8d0V6184QcM',
            },
          ]}
          prompt="Get your solidarity festival pass"
          mailingListMessage="Join our year round mailing list to receive regular updates on upcoming events, projects, and how you can support our work."
          gdprMessage="By providing my details, I consent to The World Transformed processing my data for the purposes of contacting me about their activities and ways I can support their work as well as about local Transformed events near me."
          buttonLabel="Get Tickets"
          redirectPath={`/register?${qs.stringify({ redirectPath })}`}
          additionalQueryParameters={{
            ticket: 'solidarity',
          }}
          submitButtonProps={{
            variant: 'solid',
            bg: 'twtGreen',
          }}
          initialData={{
            email: data.currentUser.email,
            firstName: data.currentUser.firstName,
            lastName: data.currentUser.lastName,
          }}
          selectAmount={{ value: 10 }}
          noPhoneNumber
          hidePersonalDetails={hidePersonalDetails}
          hideEmailAddress={hideEmailAddress}
          useProductId
          allowUserAmount={false}
        />
      </SectionWrapper>
    </>
  )
}

const SET_PROFILE_MUTATION = gql`
  mutation SetProfileQuery($input: ProfileMutationInput!) {
    profile(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`

const StandardTicketPanel = ({ onCompleted }) => {
  const { loading, data } = useQuery(USER_PROFILE, {
    fetchPolicy: 'network-only',
  })

  const { form, fields } = useGraphQlForm({
    mutation: SET_PROFILE_MUTATION,
    value: {
      approxLocation: '',
      festivalPass: 'free',
      owner: '',
    },
    onCompleted,
  })

  useEffect(() => {
    if (loading === true) {
      return
    }
    fields.owner.setValue(data.currentUser.id)
  }, [loading, data])

  return (
    <>
      <IntroBlock title="You’re almost there">
        <p>
          You have selected the Standard Festival Pass. This pass will enable
          you to book onto workshops, trainings, games and other sessions
          throughout September, gain first access to TWT’s new community forum
          and stay in-the-know with our Festival Bulletin newsletter.
        </p>
        <p>
          To register for your festival pass, please let us know where you will
          be joining TWT20 from. We like to have this information to see how far
          and wide the TWT community has spread, both nationally and
          internationally.
        </p>
      </IntroBlock>

      <SectionWrapper>
        <Form {...form.props}>
          <ContentWrapper>
            <Control label="Your location">
              <FormField
                placeholder="Eg: BS2, NE1"
                wrapper={Field}
                field={fields.approxLocation}
              />
            </Control>

            <Box marginTop={2}>
              <CaptionSmall>
                By providing my details, I consent to The World Transformed
                processing my data for the purposes of contacting me about their
                activities and ways I can support their work as well as about
                local Transformed events near me. See our{' '}
                <LinkExternal target="_blank" href="/privacy-policy">
                  Privacy Policy
                </LinkExternal>
                .
              </CaptionSmall>
            </Box>

            <input
              type="hidden"
              name="festival_pass"
              value={fields.festivalPass.value}
            />
            <input type="hidden" name="owner" value={fields.owner.value} />
          </ContentWrapper>

          <FormSubmit>
            <Button
              marginTop={3}
              alignSelf="flex-start"
              variant="solid"
              bg="twtGreen"
            >
              Register ➔
            </Button>
          </FormSubmit>
        </Form>
      </SectionWrapper>
    </>
  )
}

const SupportersNetworkPanel: React.FC<{ redirectPath?: string }> = ({
  redirectPath,
}) => {
  const { loading, data } = useQuery(USER_PROFILE, {
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <>Loading</>
  }

  let hidePersonalDetails = true
  let hideEmailAddress = false

  if (data.currentUser.firstName === '') {
    hidePersonalDetails = false
  }

  if (data.currentUser.lastName === '') {
    hidePersonalDetails = false
  }

  if (data.currentUser.email) {
    hideEmailAddress = true
  }

  return (
    <>
      <IntroBlock title="You’re almost there">
        <p>
          Thanks for choosing to become a Supporter of The World Transformed. We
          are a small independent organisation running on a very limited budget.
          Your ongoing contribution will help with the running costs of this
          year’s festival and help us deliver transformative political education
          all-year round.
        </p>
        <p>
          Most of our supporters opt to donate the equivalent of one hour's wage
          per month to help us sustain our work.
        </p>
      </IntroBlock>
      <SectionWrapper>
        <DonationForm
          mailchimpTags={['TWT2020 Supporter', 'Supporters Network']}
          amounts={[{ value: 5 }, { value: 10 }, { value: 25 }, { value: 50 }]}
          prompt="Join the supporters network"
          mailingListMessage="Join our year round mailing list to receive regular updates on upcoming events, projects, and how you can support our work."
          gdprMessage="By providing my details, I consent to The World Transformed processing my data for the purposes of contacting me about their activities and ways I can support their work as well as about local Transformed events near me."
          buttonLabel="Get Tickets"
          redirectPath={`/register?${qs.stringify({ redirectPath })}`}
          additionalQueryParameters={{
            ticket: 'supporter',
          }}
          submitButtonProps={{
            variant: 'solid',
            bg: 'twtGreen',
          }}
          initialData={{
            email: data.currentUser.email,
            firstName: data.currentUser.firstName,
            lastName: data.currentUser.lastName,
          }}
          noPhoneNumber
          hidePersonalDetails={hidePersonalDetails}
          hideEmailAddress={hideEmailAddress}
        />
      </SectionWrapper>
    </>
  )
}

const ThanksPanel = ({ ticketType, redirectPath }) => {
  const { loading, data } = useQuery(USER_PROFILE, {
    fetchPolicy: 'network-only',
  })
  const [setProfile] = useMutation(SET_PROFILE_MUTATION)

  useEffect(() => {
    if (loading === true) {
      return
    }
    setProfile({
      variables: {
        input: { owner: data.currentUser.id, festivalPass: ticketType },
      },
    })
    history && history.pushState({}, document.title, '/register')
  }, [loading])

  return (
    <>
      <IntroBlock title="Thanks">
        <p>
          Thank you for registering for a TWT21 pass. Our{' '}
          <a href="/twt21/calendar">programme</a> is now live - so you can now
          book onto loads of amazing sessions!
        </p>
        <p>
          Please help us spread the word and grow the movement by sharing this
          page too.
        </p>
        <p>See you in September</p>
      </IntroBlock>

      {redirectPath && (
        <ButtonLink to={redirectPath}>Return to {redirectPath}</ButtonLink>
      )}

      <SectionWrapper />
    </>
  )
}

type RegistrationState =
  | 'register-or-login'
  | 'pick-plan'
  | 'will-register'
  | 'will-login'
  | 'payment'
  | 'standard-demographics'
  | 'supporters-network'
  | 'thanks'

type TicketType = 'free' | 'solidarity' | 'supporter' | null

const DEBUG_INITIAL_STATE =
  typeof sessionStorage !== 'undefined' &&
  (sessionStorage.getItem('twt.registration.state.debug') as RegistrationState)

const DEBUG_TICKET_TYPE =
  typeof sessionStorage !== 'undefined' &&
  (sessionStorage.getItem('twt.registration.ticket-type.debug') as TicketType)

const USER_FESTIVAL_PASS = gql`
  query CurrentUserFestivalPass {
    currentUser {
      id
      email
      festivalTicket {
        festivalPass
      }
    }
  }
`

export const RegistrationScreen: FC<
  BoxProps & {
    size?: 'large' | 'small'
    onCompleted?: () => void
  }
> = ({ onCompleted, size, ...props }) => {
  const urlData = qs.parse(
    typeof window === `undefined` ? '' : window.location.search,
  )

  const [ticketType, setTicketType] = useState<TicketType>(
    urlData?.ticket || DEBUG_TICKET_TYPE,
  )
  const [registerState, setRegisterState] = useState<RegistrationState>(
    DEBUG_INITIAL_STATE || 'register-or-login',
  )

  const complete = () => {
    if (onCompleted) {
      onCompleted()
    } else {
      setRegisterState('thanks')
    }
  }

  const festivalPassData = useQuery<any>(USER_FESTIVAL_PASS, {
    fetchPolicy: 'network-only',
  })

  const isLoggedIn = (data: typeof festivalPassData['data']) => {
    return !!data?.currentUser?.id
  }

  const getPass = (data: typeof festivalPassData['data']) => {
    return data?.currentUser?.festivalTicket?.festivalPass
  }

  const hasAPass = (data: typeof festivalPassData['data']) => {
    return !!getPass(data)
  }

  useEffect(
    function checkAlreadyRegistered() {
      if (hasAPass(festivalPassData.data)) {
        setTicketType(getPass(festivalPassData.data))
        complete()
      }
    },
    [festivalPassData.data],
  )

  useEffect(
    function recalculateViewState() {
      if (!isLoggedIn(festivalPassData.data) && ticketType === null) {
        setRegisterState('register-or-login')
        return
      }

      if (!isLoggedIn(festivalPassData.data) && !!ticketType) {
        setRegisterState('will-register')
        return
      }

      if (hasAPass(festivalPassData.data)) {
        complete()
        return
      }

      if (urlData.donatedSuccessfully && urlData.donatedSuccessfully === '1') {
        complete()
        return
      }

      if (ticketType === null) {
        // Upon logging in, if for some reason you don't have a pass.
        // then we'll send you back to choose a festival pass and continue on.
        setRegisterState('pick-plan')
        return
      }

      if (ticketType === 'solidarity') {
        setRegisterState('payment')
        return
      }

      if (ticketType === 'free') {
        setRegisterState('standard-demographics')
        return
      }

      if (ticketType === 'supporter') {
        setRegisterState('supporters-network')
        return
      }
    },
    [setRegisterState, ticketType, festivalPassData.data],
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [registerState])

  let panel = null

  switch (registerState) {
    case 'register-or-login':
      panel = (
        <IntroductionPanel
          size={size}
          allowLogin={true}
          optionHandler={(option) => () => {
            if (option === 'will-login') {
              setRegisterState('will-login')
            } else {
              setTicketType(option)
              setRegisterState('will-register')
            }
          }}
        />
      )
      break
    case 'pick-plan':
      panel = (
        <IntroductionPanel
          size={size}
          allowLogin={false}
          title={'Choose your festival pass'}
          optionHandler={(option) => () => setTicketType(option)}
        />
      )
      break
    case 'will-register':
      panel = (
        <RegisterPanel
          type="registration"
          onSuccess={async () => {
            await festivalPassData.refetch()
          }}
        />
      )
      break
    case 'will-login':
      panel = (
        <RegisterPanel
          type="login"
          onSuccess={async () => {
            await festivalPassData.refetch()
          }}
        />
      )
      break
    case 'standard-demographics':
      panel = <StandardTicketPanel onCompleted={() => complete()} />
      break
    case 'payment':
      panel = (
        <PaymentPanel
          redirectPath={
            typeof window !== 'undefined' ? window.location.pathname : undefined
          }
        />
      )
      break
    case 'supporters-network':
      panel = (
        <SupportersNetworkPanel
          redirectPath={
            typeof window !== 'undefined' ? window.location.pathname : undefined
          }
        />
      )
      break
    case 'thanks':
      panel = (
        <ThanksPanel
          ticketType={ticketType}
          redirectPath={urlData?.redirectPath}
        />
      )
      break
  }

  return (
    <Box minHeight="500px" {...props}>
      {ticketType && (
        <SectionWrapper
          css={{ float: 'right' }}
          paddingVertical={{ mobile: 0, desktop: 0 }}
          right={0}
          showFrom="desktop"
          maxWidth="45%"
          width="397px"
          z={2}
        >
          <Ticket type={ticketType} />
        </SectionWrapper>
      )}
      {panel}
    </Box>
  )
}
